<template>
	<page>
		<module>
			<div class="flex-column flex-center" style="height: 100%;">404</div>
		</module>
	</page>
</template>

<script>
	export default {
		data() {
			return {
			}
		},
		
		methods: {
		}
	}
</script>

<style lang="stylus" scoped>
</style>
